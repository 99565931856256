<template>
    <div class="project_check_list">
        <el-card>
            <nav class="input" style="width: 300px">
                <el-input v-model="search.keyword" size="medium" placeholder="用户ID/姓名/手机号">
                    <el-select v-model="search.keywordType" slot="prepend" style="width: 100px;" placeholder="请选择">
                        <el-option label="发信人" value="1"></el-option>
                        <el-option label="收信人" value="2"></el-option>
                    </el-select>
                </el-input>
            </nav>
            <nav class="input" style="width: auto">
                <el-select v-model="search.status" size="medium" placeholder="状态">
                    <el-option :label="item.label" :value="item.value" :key="index" v-for="(item, index) in statusList">
                    </el-option>
                </el-select>
            </nav>
            <nav class="input" style="width: auto">
                <el-select v-model="search.source" size="medium" placeholder="类型">
                    <el-option :label="item.value" :value="item.key" :key="index" v-for="(item, index) in source">
                    </el-option>
                </el-select>
            </nav>
            <label
                for=""
                style="
                        font-size: 14px;
                        float: left;
                        transform: translateY(9px);
                    "
                >发信时间：</label
            >

            <nav class="input">
                <el-date-picker
                    v-model="search.createTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width: 100%"
                    size="medium"
                />
            </nav>
            <nav class="input">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ btn ? "搜索" : "loading.." }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" :storage.sync="search" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="sendUserName" label="发信人"></el-table-column>
                    <el-table-column prop="receiveUserName" label="收信人"></el-table-column>
                    <el-table-column prop="content" label="私信内容"></el-table-column>
                    <el-table-column prop="sourceName" label="来源"></el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="{ row }">
                            <el-tag size="small" type="danger" v-if="row.status == 0">未读</el-tag>
                            <el-tag size="small" v-else>已读</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>

                    <!-- 
                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button
                                type="text"
                                icon="el-icon-edit"
                                size="mini"
                                style="color: blue;margin: 3px;"
                                @click="add(row)"
                            >
                                编辑</el-button
                            >
                            <el-popconfirm
                                @confirm="hot(row, row.top ? 0 : 1)"
                                title="是否继续操作"
                                style="margin: 5px;"
                            >
                                <el-button slot="reference" type="text" icon="el-icon-share" size="mini" class="a5">{{
                                    row.top ? "不推荐" : "推荐"
                                }}</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column> -->
                </el-table>
            </z-table>
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                createTime: [],
                keyword: "",
                status: "",
                source: "",
                keywordType: "1",
            },
            detail: {
                title: "加载中...",
            },
            btn: true,
            configDetail: {},
            statusList: [
                { label: "全部", value: "-1" },
                { label: "未读", value: "0" },
                { label: "已读", value: "1" },
            ],
            source: [],
        };
    },

    activated() {
        // console.log(1);
    },
    components: {},
    created() {
        // this.search.activityId = this.$route.query.id;
        this.init();
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },
        async init() {
            await this.getConfigDictionaries();
        },
        async getConfigDictionaries() {
            let {
                data: {
                    data: { source },
                },
            } = await this.$http.get("/admin/Common/getConfigDictionaries", { params: { config: "log" } });
            source.unshift({ key: -1, value: "全部" });
            this.source = source;
        },

        setUp() {
            this.$refs.Setting.init();
        },
        add({ activityId: id }) {
            this.$router.push({ name: "activity_speaker_ppt", query: { id } });
        },
        async subimt() {
            this.btn = false;
            await this.$refs.table.refresh();
            this.btn = true;
        },

        async queryList(val) {
            let params = { ...val, ...this.search };
            params.createTime = params.createTime.join("~");
            params.status = params.status === "" ? -1 : params.status;
            let { data: res } = await this.$http.get("/admin/Logs/getMessageList", {
                params,
            });
            if (res.errorCode == 200) {
                this.$refs.table.complete(res.data);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
